import {
  createOrganizationUserPreset,
  deleteOrganizationUserPreset,
  updateOrganizationUserPreset
} from '@/api/organizationUserPreset';

import { t } from '@/i18next';
import { parseUserParams } from '@/lib/modals/accountsModals';

import {
  CREATE_EDIT_ORGANIZATION_USER_PRESET_MODAL,
  DELETE_ENTITY_MODAL
} from '@/constants/modals';

import { ORGANIZATION_USER_PRESETS_API } from '@/constants/api';

import type {
  CreateOrganizationUserPresetParams,
  UpdateOrganizationUserPresetParams
} from '@/api/organizationUserPreset/types';

import type { OrganizationUserPresetSimplified } from '@/types/organizationUserPresets';
import type { PromiseResolve } from '@/types/helpers';
import type { UseModal } from '@/types/modals';

export function openCreateOrganizationUserPresetModal(
  openModal: UseModal['openModal']
) {
  async function onCreateOrganizationUserPresetHandler(
    resolve: PromiseResolve,
    organizationUserPresetParams: CreateOrganizationUserPresetParams
  ) {
    const data = await createOrganizationUserPreset(
      parseUserParams(
        organizationUserPresetParams
      ) as CreateOrganizationUserPresetParams
    );
    resolve(data);
  }

  openModal<typeof CREATE_EDIT_ORGANIZATION_USER_PRESET_MODAL>(
    CREATE_EDIT_ORGANIZATION_USER_PRESET_MODAL,
    {
      confirmAction: onCreateOrganizationUserPresetHandler,
      useStatusConfirmation: true,
      invalidateQueryKey: ORGANIZATION_USER_PRESETS_API,
      successMessage: 'common.createdSuccessfully',
      allowGoBack: true
    }
  );
}

export function openDeleteOrganizationUserPresetModal(
  openModal: UseModal['openModal'],
  organizationUserPreset: OrganizationUserPresetSimplified
) {
  async function onDeleteOrganizationUserPresetHandler(
    resolve: PromiseResolve
  ) {
    const data = await deleteOrganizationUserPreset(organizationUserPreset.id);
    resolve(data);
  }

  openModal<typeof DELETE_ENTITY_MODAL>(DELETE_ENTITY_MODAL, {
    confirmAction: onDeleteOrganizationUserPresetHandler,
    useStatusConfirmation: true,
    invalidateQueryKey: ORGANIZATION_USER_PRESETS_API,
    name: organizationUserPreset.name,
    confirmTranslation: 'common.delete',
    modalMessage: 'organization.delete_user_preset',
    successMessage: 'common.deletedSuccessfully',
    successMessageParams: {
      entityType: t('manageAccounts.user_preset'),
      entityName: organizationUserPreset.name
    }
  });
}

export function openEditOrganizationUserPresetModal(
  openModal: UseModal['openModal'],
  activePreset: OrganizationUserPresetSimplified
) {
  async function onEditOrganizationUserPresetHandler(
    resolve: PromiseResolve,
    organizationUserPresetParams: UpdateOrganizationUserPresetParams
  ) {
    const data = await updateOrganizationUserPreset(
      organizationUserPresetParams.id,
      parseUserParams(
        organizationUserPresetParams
      ) as UpdateOrganizationUserPresetParams
    );
    resolve(data);
  }

  openModal<typeof CREATE_EDIT_ORGANIZATION_USER_PRESET_MODAL>(
    CREATE_EDIT_ORGANIZATION_USER_PRESET_MODAL,
    {
      confirmAction: onEditOrganizationUserPresetHandler,
      useStatusConfirmation: true,
      activePreset,
      invalidateQueryKey: ORGANIZATION_USER_PRESETS_API,
      successMessage: 'common.updatedSuccessfully',
      allowGoBack: true
    }
  );
}
